export default class Ui {

    constructor() {
        this.nrwBP = 768;
        this.init();
    }

    init() {
        this.pageTop();
        this.lightcase();
        this.modal();
        this.slider();
    }

    pageTop() {
        $('.js-pagetop').on('click', function () {
            TweenLite.to(window, 1, { scrollTo: { y: 0, autoKill: false, ease: Circ.easeOut } });
        });
    }

    lightcase() {
        $('a[data-rel^=lightcase]').lightcase({
            maxHeight: 1200,
            shrinkFactor: .9
        });
    }

    modal() {

        //open
        $('.js-modal-open').on('click', function(){
            const modal = $(this).attr('data-modal');
            $(modal).addClass('-active');
        });

        //close
        $('.js-modal-close').on('click', function(){
            const modal = $(this).parents().find('.js-modal');
            modal.removeClass('-active');
        });

    }

    slider() {

        $('.js-slick').slick({

            autoplay: true,
            autoplaySpeed: 3000,
            dots: true,
            arrows: false,

        });

    }
}