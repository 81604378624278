export default class Share {

    constructor() {
        this.init();
    }

    init() {
        this.shareThisPage();
    }

    //--------------------------------
    // 現在のページをシェア
    //--------------------------------
    shareThisPage(extendService) {
        //tweetはページタイトルとURL
        var default_title = '';
        var e_url = encodeURIComponent(location.href);
        var e_title = encodeURIComponent(document.title);
        var e_hash = encodeURIComponent('');

        //オーバーライドする場合は、data-sharetextを挿入
        var overwriteText = $('.js-set_share--twitter').attr('data-sharetext') || '';
        if (overwriteText !== '') {
            overwriteText = overwriteText + ' | ' + default_title;
            e_title = encodeURIComponent(overwriteText);
        }


        var services = {
            twitter: {
                $dom: $('.js-set_share--twitter'),
                url: '//twitter.com/intent/tweet?url=' + e_url + '&text=' + e_title + '&hashtags=' + e_hash
            },
            line: {
                $dom: $('.js-set_share--line'),
                url: 'https://line.me/R/msg/text/?' + e_title + ' ' + e_url
            }
        };


        if (extendService) {
            $.extend(true, services, extendService);
        }

        $.each(services, function (index, value) {
            if (value.$dom.length > 0) {
                value.$dom.attr('href', value.url);
            }
        });

    };
}